import React, { useRef } from 'react'
import Metadata from 'src/components/Metadata'
import Layout from 'src/theme/CustomAppreciationsLayout'
import { META } from 'src/utils/seo'
import styled from 'styled-components'
import Appreciation from '../features/custom/appreciations/components/Appreciation'
import BubbleBackground from 'src/components/BubbleBackground'
import DeviceDetector from 'src/components/DeviceDetector'

const appreciations = [
  {
    id: '1',
    title: 'Chciałabym wyrazić swoją wdzięczność Paniom w rejestracji na chemioterapię',
    descriptions: ['Dziękuję, że zawsze można do Pań zadzwonić i konsultują Panie moje pytania z lekarzem, co daje mi zarówno doraźną pomoc, jak również poczucie bezpieczeństwa, że jestem zaopiekowana. Za każdym razem głos Pań w słuchawce jest miły i uśmiechnięty, to bardzo podnosi na duchu i szczerze za to dziękuję.', 'Dziękuję, że dopasowują Panie godziny do moich preferencji i pomimo dużej liczby pacjentów poświęcacie mi tyle czasu ile potrzebuję, aby wszystkiego się dowiedzieć.']
  },
  {
    id: '2',
    title: 'Chciałabym wyrazić swoją wdzięczność Paniom w rejestracji na chemioterapię',
    descriptions: ['Dziękuję za ogrom cierpliwości i morze empatii.', 'Zdarzało mi sie zadać pytanie o rzeczy, które są zapisane w komputerze i nie ma tam prawa do pomyłki, mimo tego Pani nie okazała zniecierpliwienia, tylko spokojnym głosem z uśmiechem sprawdziła i powiedziała, że mam pytać, kiedy tylko mam jakieś wątpliwości. To niesamowite jaki wiele ciepła i dobroci mi Panie okazały, jestem za to ogromnie wdzięczna.']
  },
  {
    id: '3',
    title: 'Chciałabym wyrazić swoją wdzięczność Paniom w rejestracji na chemioterapię',
    descriptions: ['Dziękuję Pani, która nosi nasze karty na oddział, nigdy nie czeka aż jej się “nazbiera” więcej, a idzie z każdą kartą, abyśmy mogli jak najszybciej otrzymać leki i jechać do domu. To niesamowite jak potrafi się Pani wczuć w naszą sytuację, jestem za to ogromnie wdzięczna.', 'Dziękuję za kanapki, które dostałam nie raz, czasami głupio było mi wziąć, pomimo, że byłam głodna, ale dzięki temu, że pytacie kilka razy, to chętnie z nich skorzystałam. Czasem zdarza się kilka godzin czekania, więc te kanapki “ratują życie”, bardzo za to dziękuję.']
  },
  {
    id: '4',
    title: 'Chciałabym wyrazić swoją wdzięczność Pielęgniarkom',
    descriptions: ['Dziękuję za każdy uśmiech i każde dobre słowo, za dobre rady i tipy, co zrobić by poczuć się lepiej.', 'To niesamowite jak szybko wykonują Panie wszystkie czynności i zawsze są Panie w pobliżu. Pomimo ogromu zmęczenia tempo pracy pozostaje niezmienne. Bardzo to doceniam i jestem pełna podziwu patrząc na Wasze zaangażowanie.', 'Dziękuję, że nigdy nie pozwalacie na siebie czekać, gdy tylko ktoś zawoła, za chwile jesteście i “naprawiacie” nasze wenflony, albo podłączacie kolejne eliksiry.']
  },
  {
    id: '5',
    title: 'Chciałabym wyrazić swoją wdzięczność Pielęgniarkom',
    descriptions: ['Bardzo dziękuję, że dbają Panie o to, aby podłączenie leków do portu odbywało się zgodnie z procedurą. Pamiętacie o przeczyszczeniu portu i pilnujecie, aby wszystko było prawidłowo podłączone.', 'Jest taka jedna pielęgniarka, która robi to z wyjątkową dbałością, zawsze 2 razy  sprawdzając czy wszystko działa prawidłowo, płucze podwójnie i sprawdza drożność portu. Kiedy trafię do tej Pani, jestem szczęśliwa, bo wiem, że dostanę jeszcze “dodatkowy przegląd serwisowy”, dzięki czemu port będzie służył jeszcze dłużej. Bardzo dziękuję, że jest Pani taka dokładna i troszczy się, aby wszystko było prawidłowe i drożne.']
  },
  {
    id: '6',
    title: 'Chciałabym wyrazić swoją wdzięczność Pielęgniarkom',
    descriptions: ['Dziękuję za każde dobre słowo i uśmiech, który podnosi mnie na duchu. To wspaniałe, że pomimo natłoku zajęć mają Pani czas i ochotę, aby z nami pogawędzić czy pożartować. Zadziwia mnie to, że zawsze pamiętacie na której sali jesteśmy, co dla mnie przy takiej ilości pacjentów wydaje się niemożliwe do zapamiętania. Dziękuję za dobre rady i wskazówki jak sobie radzić, aby było lepiej i za każde dobre słowo. Bardzo mnie to podnosi na duchu i sprawia, że pobyt u Was jest dużo przyjemniejszy.']
  },
  {
    id: '7',
    title: 'Chciałabym wyrazić swoją wdzięczność Pielęgniarkom',
    descriptions: ['Dziękuję, że za każdym razem dokładnie sprawdzacie czy leki, które podłączacie są dla nas i pomimo tak dużej liczby pacjentów nigdy nie zdarzyła się pomyłka.', 'Jestem wdzięczna, że dla każdego pacjenta zmieniacie rękawiczki, a wszystko wykonujecie z zachowaniem najwyższej ostrożności. Dziękuję, że nie szkoda Wam na to czasu i zasobów, bo to znaczy że nasze zdrowie i życie jest dla Was ważne, a to poczucie dodaje mi otuchy i chęci do dalszej walki.']
  },
  {
    id: '8',
    title: 'Chciałabym wyrazić swoją wdzięczność Pielęgniarkom',
    descriptions: ['Za to, że z taką cierpliwością “gasicie pożary w poczekalni”, gdy pacjenci denerwują się długim czekaniem.', 'Dziękuję, że dbacie o naszą wygodę i ze wszystkich sił staracie się, aby nasz pobyt na oddziale był jak najbardziej komfortowy. Zawsze proponujecie nam łóżka pomimo, że fotele są łatwiejsze w dezynfekcji i nawet podczas przerwy śniadaniowej przełączacie nam kroplówki. Jestem za to bardzo wdzięczna i z całego  serca dziękuję.']
  },
  {
    id: '9',
    title: 'Chciałabym wyrazić swoją wdzięczność Pielęgniarkom w laboratorium',
    descriptions: ['To niesamowite, że zawsze znajdziecie żyłę w miejscach, których nic nie widać. Dziękuję, że używacie takich cienkich igieł i zawsze trafiacie za pierwszym razem. U Was pobranie krwi często jest bezbolesne co wcześniej nigdy mi się nie zdarzyło, a to świadczy o Waszym profesjonalizmie i delikatności. Dziękuję, że dbacie o to, aby nie bolało, to bardzo wiele dla mnie znaczy, gdy całe ciało jest obolałe od leczenia.', 'To niesamowite, że przy takiej precyzji badania są wykonywane szybko i sprawnie, rzadko kiedy czekam dłużej niż 10 minut przed gabinetem, a wyniki są zawsze na czas lub przed czasem. Dziękuję.']
  },
  {
    id: '10',
    title: 'Chciałabym wyrazić swoją wdzięczność Pielęgniarkom w zabiegowym',
    descriptions: ['Bardzo dziękuję, że tak powoli wstrzykuje mi Pani herceptynę, zagadując przy tym, aby odwrócić moją uwagę. Kiedy okazało się, że to dla mnie tak bolesny zastrzyk byłam bardzo zestresowana przed kolejnym. Pani podala to tak delikatnie, że mój strach zniknął. Dziękuję, że jest Pani zawsze miła i uśmiechnięta, wysłuchuje moich bolączek i doskonale potrafi okazać empatię. Dziękuję także za cenne wskazówki i dobre rady, to naprawdę wiele dla mnie znaczy.']
  }
]

export default () => {

  const renderList = appreciations.map((appreciation, index) =>
    <Appreciation key={`appreciation-${index}`} data={appreciation} />
  )

  return (
    <>
      <Metadata meta={META.customAppreciations} />
      <Layout>
        <DeviceDetector>
          <div className='web'>
            <BubbleContainer>
              <BubbleBackground />
            </BubbleContainer>
            <AppreciationsViewContainer>
              <div className='appreciations-view__list'>
                {renderList}
              </div>
            </AppreciationsViewContainer>
          </div>
          <div className='mobile'>
            <AppreciationsViewContainer>
              <div className='appreciations-view__list'>
                {renderList}
              </div>
            </AppreciationsViewContainer>
          </div>
        </DeviceDetector>
      </Layout>
    </>
  )
}

const BubbleContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
`

const AppreciationsViewContainer = styled.div`
  display: inline-flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 100%;
  z-index: 2;

  ${props => props.theme.breakpointsMedia.mobile} {
    display: block;
    max-width: 100%;
    padding: 0;
  }

  .appreciations-view__list {
    max-width: 670px;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    display: inline-block;
    flex: 2;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      max-width: 100%;
      padding: 0;
    }
  }
`

