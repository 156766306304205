import React from 'react'
import styled from 'styled-components'

import Paper from 'src/components/atoms/Paper'
import pandaIllustration from '../../../../assets/images/landing/Panda4.svg'

export const CustomAppreciation = (props) => {

  const renderDescriptions = props.data.descriptions.map((description, index) => (
    <Description key={`${props.data.id}-description-${index}`}>{description}</Description>
    )
  )

 return (
   <Container>
     <Paper>
       <HeadingContainer>
         <PandaIllustration src={pandaIllustration} alt='panda-illustration' />
         <div>
           <Quote>
             <i>Każdy gest, nawet najdrobniejszy... zmienia świat, czyjś świat.</i>
           </Quote>
           <Title>{props.data.title}</Title>
         </div>
       </HeadingContainer>
       {renderDescriptions}
       <FooterContainer>
       </FooterContainer>
     </Paper>
   </Container>
 )
}

export default CustomAppreciation

const Container = styled.div`
  margin-top: 16px;
`

const Title = styled.h4`
  font-size: 16px;
`

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`

const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
`

const HeartIllustration = styled.img`
  width: 60px;
`

const PandaIllustration = styled.img`
  width: 80px;
`

const Quote = styled.div`
  text-align: right;
  color: ${props => props.theme.palette.common.dustyGray};
  margin-bottom: 16px;
`

const Description = styled.div`
  margin-top: 16px;
`
