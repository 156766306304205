import React from 'react'
import styled from 'styled-components'

import { NonAuthNavigation } from 'src/components/Navigation/Navigation'
import Footer from 'src/components/Footer'

const Layout = ({ children }) => {
  return (
    <LayoutContainer>
      <NonAuthNavigation />
      <Content>{children}</Content>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </LayoutContainer>
  )
}

export default Layout

const LayoutContainer = styled.div`
  height: 100%;
  min-height: calc(100vh - ${props => props.theme.dimensions.navHeight});
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  margin-top: ${props => props.theme.dimensions.navHeight};
  //background-color: ${props => props.theme.palette.common.white};
`

const Content = styled.div`
  width: 100%;
  flex: 1;
`

const FooterContainer = styled.div`
  position: relative;
  z-index: 2;
`
